<template>
  <section class="section py-5 px-0">
    <b-field label="Mis Videos" class="px-5"></b-field>  

    <div class="no-videos px-5"  v-if="!userHasMedia" >
      <div class="has-background-white-ter">
        <d-icon icon="FaRegFilm" size="is-normal"/>
        <div>Aún no tenés videos</div>
      </div>
    </div>
    
    <vue-glide v-if="userHasMedia" :perView="1" :peek="20" :gap="5" :key="sliderId" :swipeThreshold="enableSwipe">
      <!-- Slides skeleton mientras carga -->
      <template v-if="loading">
        <vue-glide-slide>
          <b-skeleton/>
        </vue-glide-slide>
        <vue-glide-slide>
          <b-skeleton/>
        </vue-glide-slide>
      </template>
      <!-- Slides de videos posta -->
      <template v-else>
        <!-- User Media -->
        <vue-glide-slide v-for="media in userMedia" :key="media.id">
          <template v-if="media.converting">
            <div class="backgroundThumbnail" :style="{backgroundImage: media.thumbnail?.url ? `url(${media.thumbnail.url})` : 'none'}">
              <div class="converting">
                <div>Tu video se está procesando</div>
                <d-icon icon="FaSpinnerThird" extraclass="fa-rotate" size="is-medium"/>
              </div>
            </div>
          </template>
          <template v-else>
            <!-- <video :src="`${video.url}#t=0.1`" preload="auto" @click="FullscreenVideo(video)" disableRemotePlayback muted playsinline loop></video> -->
            <template v-if="media.thumbnail">
              <img :src="media.thumbnail.url" @click="FullscreenVideo(media.video)"/>
            </template>
            <template v-else>
              <video :src="`${media.video.url}#t=0.1`" preload="auto" @click="FullscreenVideo(media.video)" disableRemotePlayback muted playsinline loop></video>
            </template>
            <div v-if="media.video?.url" class="playIcon">
              <d-icon icon="FaPlay" size="is-large" style="color:white"/>
            </div>
          </template>
        </vue-glide-slide>
        <!-- Videos -->
        <vue-glide-slide v-for="video in videos" :key="video.id">
          <template v-if="video.converting">
            <div class="converting">
              <div>¡Ya casi está listo tu video!</div>
              <d-icon icon="FaSpinnerThird" extraclass="fa-rotate" size="is-medium"/>
            </div>
          </template>
          <template v-else>
            <video :src="`${video.url}#t=0.1`" preload="auto" @click="FullscreenVideo(video)" disableRemotePlayback muted playsinline loop></video>
          </template>
        </vue-glide-slide>
        <!-- Photos -->
        <vue-glide-slide v-for="photo in photos" :key="photo.id">
          <img :src="photo.sizes.mobile.url" @click="FullscreenImage(photo)"/>
        </vue-glide-slide>
      </template>
    </vue-glide>
    
    <!-- Fullscreen video modal -->
    <b-modal v-model="isVideoCardModalActive">
      <div class="card" v-if="fullscreenVideo != null">
          <div class="card-image p-2">
              <figure class="image">
                <video disableRemotePlayback autoplay muted loop playsinline>
                  <source :src="fullscreenVideo.url" :type="fullscreenVideo.mimeType">
                  Your browser does not support the video tag.
                </video>
              </figure>
          </div>
          <div class="card-content pt-2 pb-4">
              <p class="has-text-centered">
                <!-- Share Video -->
                <b-button type="is-primary" size="is-medium" @click="ShareVideo()" class="mr-2" :loading="fetchingVideoShare">
                  <d-icon icon="FaShareNodes" size="is-medium"/>
                </b-button>
                <!-- Download video -->
                <a :href="fullscreenVideo.url" download class="button is-medium is-outlined ml-2" _target="blank" @click="AnalyticsDownloadVideo">
                  <d-icon icon="FaDownload" size="is-medium"/>
                </a>
              </p>

          </div>
      </div>
    </b-modal>

    <!-- Fullscreen image modal -->
    <b-modal v-model="isPhotoCardModalActive">
      <div class="card" v-if="fullscreenImage != null">
          <div class="card-image">
              <figure class="image">
                  <img :src="fullscreenImage.sizes.mobile.url">
              </figure>
          </div>
          <div class="card-content">
              <div class="media">
                  <div class="media-content">
                      <p class="title is-4">{{ fullscreenImage.experience.title }}</p>
                      <!-- <p class="subtitle is-6">@johnsmith</p> -->
                  </div>
              </div>
    
              <div class="content">
                  <a href="#">Compartí esta imagen</a>
              </div>
          </div>
      </div>
    </b-modal>
  
  </section>
</template>

<script>
import 'vue-glide-js/dist/vue-glide.css';
import { Glide, GlideSlide } from 'vue-glide-js';
import axios from 'axios';

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  props: {},
  data() {
    return {
      sliderId: 0,
      loading: true,
      userMedia: [],
      photos: [], // deprecated. TODO borrar
      videos: [], // deprecated. TODO borrar
      fullscreenImage: null,
      fullscreenVideo: null,
      isPhotoCardModalActive: false,
      isVideoCardModalActive: false,
      fetchingVideoShare: false,
    };
  },
  computed: {
    userHasMedia(){
      return this.photos.length > 0 || this.videos.length > 0 || this.userMedia.length > 0
    },
    enableSwipe(){
      const defaultValue = 80
      if(this.loading) return 0 
      if(this.photos.length > 1 || this.videos.length > 1 || this.userMedia.length > 1) return defaultValue
      return 0
    }
  },
  methods: {
    AnalyticsDownloadVideo(){
      this.$TrackUserEvent("Download Video")
      console.log("Analytics Download Video")
    },
    FullscreenVideo(video){
      console.log("Fullscreen Video", video)
      this.fullscreenVideo = video
      this.isVideoCardModalActive = true
    },
    async ShareVideo(){
      try {
        console.log("share video")
        this.fetchingVideoShare = true;
        const response = await fetch(this.fullscreenVideo.url);
        if (!response.ok) {
          this.fetchingVideoShare = false;
          throw new Error('Network response was not ok');
        }

        const videoBlob = await response.blob();
        console.log('Blob type:', videoBlob.type); // Log the blob type

        const videoFile = new File([videoBlob], 'TOM-Experiencia-Photobooth.mp4', { type: 'video/mp4' });
        console.log('File:', videoFile); // Log the file

        let shareData = {
          files: [videoFile],
        };
        console.log(shareData)
        
        if (navigator.share && typeof navigator.share === 'function') {
          if (navigator.canShare && navigator.canShare(shareData)) {
            this.fetchingVideoShare = false;
            await navigator.share(shareData);
             // Analytics
             this.$TrackUserEvent("Share Video")
            console.log('Video shared successfully');
          } else {
            // File sharing not supported
            console.error('File sharing is not supported in this browser.');
            this.fetchingVideoShare = false;
          }
        } else {
          // Fallback for browsers that do not support Web Share API
          // Convert blob to data URL and provide a link or use other methods for sharing
          const reader = new FileReader();
          reader.onload = function(event) {
            const dataUrl = event.target.result;
            // Use the data URL for sharing or provide a download link
            console.log('Data URL:', dataUrl);
            // Handle sharing or provide fallback for sharing on non-supported browsers
          };
          reader.readAsDataURL(videoBlob);
          this.fetchingVideoShare = false;
        }
      } catch (error) {
        // Handle errors here
        console.error('Error fetching and sharing video:', error);
        this.fetchingVideoShare = false;
      }
    },
    isIOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
    FullscreenImage(image){
      // console.log("Fullscreen Image")
      this.fullscreenImage = image
      this.isPhotoCardModalActive = true
    },
    async FetchUserMedia(openLastMedia = false){
      const res = await axios.get(process.env.VUE_APP_API_BASE + `/api/usermedia?where[users][equals]=${this.$store.state.user.profile.id}`);
      if(res.status == 200){
        this.userMedia = res.data.docs;
        this.sliderId++; // para forzar recargar
        if(openLastMedia && this.userMedia.length > 0){
          if(this.userMedia[0].converting){
            // Si el ultimo video está en conversion, no lo abro
            return
          }
          this.FullscreenVideo(this.userMedia[0].video)
        }
      }else{
        console.warn("Error al cargar las fotos", res)
      }
    },
    async FetchVideos(openLastVideo = false){
      const resVideo = await axios.get(process.env.VUE_APP_API_BASE + `/api/uservideos?where[users][equals]=${this.$store.state.user.profile.id}`);
      if(resVideo.status == 200){
        this.videos = resVideo.data.docs;
        this.sliderId++; // para forzar recargar
        if(openLastVideo && this.videos.length > 0){
          if(this.videos[0].converting){
            // Si el ultimo video está en conversion, no lo abro
            return
          }
          this.FullscreenVideo(this.videos[0])
        }
      }else{
        console.warn("Error al cargar las fotos", resVideo)
      }
    },
    async FetchPhotos(openLastPhoto = false){
      const resPhoto = await axios.get(process.env.VUE_APP_API_BASE + `/api/userimages?where[users][equals]=${this.$store.state.user.profile.id}`);
      this.loading = false;
      if(resPhoto.status == 200){
        this.photos = resPhoto.data.docs;
        this.sliderId++; // para forzar recargar
        if(openLastPhoto && this.photos.length > 0){
          if(this.photos[0].converting){
            // Si el ultimo video está en conversion, no lo abro
            return
          }
          this.FullscreenVideo(this.photos[0])
        }
      }else{
        console.warn("Error al cargar las fotos", resPhoto)
      }
    }
  },
  sockets: {
    UserVideoCreated(){
      this.FetchUserMedia(true);
    },
    UserVideoEdited(){
      this.FetchUserMedia(true);
    },
  },
  async mounted() {
    this.FetchUserMedia();
    this.FetchVideos();
    this.FetchPhotos();
  },
};
</script>

<style scoped lang="scss">

.backgroundThumbnail{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.card {
  overflow: hidden;

  .image {
    aspect-ratio: 1.333;
    background: linear-gradient(90deg, hsl(0deg, 0%, 86%) 25%, rgba(219, 219, 219, 0.5) 50%, hsl(0deg, 0%, 86%) 75%);
    background-size: 400% 100%;
    animation: skeleton-loading 1.5s infinite;

    img {
      max-height: 350px;
      object-fit: contain;
    }
  }

  video {
    display: block;
    max-height: 55dvh;
    margin-inline: auto;
  }


}

.b-skeleton-item {
  aspect-ratio: 1.333;
}

.no-videos {
 
  & > div {
    width: 100%;
    aspect-ratio: 1.33333333;
    border-radius: 4px;
    background-color: hsl(0, 0%, 96%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
}

</style>

<style lang="scss">
.glide {
  .glide__slide{
    position: relative;
  }
  img{
    width: 100%;
    border-radius: 4px;
    aspect-ratio: 1.333;
    object-fit: cover;
  }
  video {
    width: 100%;
    border-radius: 4px;
    aspect-ratio: 1.333;
    background-color: hsl(0deg, 0%, 86%);
    background: linear-gradient(90deg, hsl(0deg, 0%, 86%) 25%, rgba(219, 219, 219, 0.5) 50%, hsl(0deg, 0%, 86%) 75%);
    background-size: 400% 100%;
  }
  .converting{
    width: 100%;
    aspect-ratio: 1.33333333;
    border-radius: 4px;
    background-color: hsl(0deg 0% 100% / 60%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    backdrop-filter: blur(8px);
  }
  .playIcon{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    pointer-events: none;
  }
}
</style>